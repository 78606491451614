@use '../../../styles/colors' as *;

.imageContainer {
  position: relative;

  .gradient {
    position: absolute;
    top: calc(-100% + 1px);
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(transparent, $blue-3);
  }

  img {
    display: block;
    width: 100%;
    min-height: 85px;
    object-fit: cover;
    object-position: left;
    background: $blue-3;
  }
}
