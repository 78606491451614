@use '../../../styles/colors' as *;

.svg {
  overflow: visible;

  .star {
    stroke: white;
  }

  .connection {
    stroke: rgba($blue-3, 0.4);
  }

  * {
    transition: all 6s linear;
    vector-effect: non-scaling-stroke;
    stroke-linecap: round;
  }
}
