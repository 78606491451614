@use '../../../../styles/colors' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $blue-4;
  white-space: nowrap;

  .value {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .label {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
