@use '../../../styles/colors' as *;
@use '../../../styles/mixins/device' as *;

.section {
  display: flex;
  flex-direction: column;
  min-width: 1px;

  .frameworkName {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: $blue-4;
  }

  .summary {
    display: flex;
    justify-content: center;
    gap: 100px;
    margin: 20px;

    @include mobile {
      justify-content: space-around;
      gap: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.5px;
    text-align: justify;
    color: $blue-5;
  }

  .toolsMarquee {
    width: 90%;
    align-self: center;
  }

  .tool {
    display: inline-block;
    margin: 12px;
    font-size: 22px;
    color: $blue-5;
  }

  @include device(tablet, laptop) {
    min-width: 340px;
  }

  @include mobile {
    min-width: 300px;
  }
}
