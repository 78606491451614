@use '../../../styles/mixins/device' as *;

.paragraph {
  margin: 8px 0;
  font-size: 25px;
  font-weight: 300;
  line-height: 35px;
  text-align: justify;
  letter-spacing: 0.5px;

  &:first-letter {
    font-weight: 500;
  }

  a {
    font-weight: 500;

    &:not(:hover) {
      text-decoration: underline;
      text-decoration-thickness: 1.5px;
    }
  }

  @include mobile {
    margin: 4px 0;
    font-size: 21px;
    line-height: 28px;
  }
}
