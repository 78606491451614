@function opacify($color, $opacity) {
  @return adjust-color($color, $alpha: -$opacity);
}

$white-1: white;
$black-1: black;
$black-2: #111111;
$black-3: rgb(75, 75, 75);
$gray-1: rgb(102, 102, 102);
$gray-2: rgb(150, 150, 150);
$gray-3: rgb(210, 210, 210);
$gray-4: rgb(230, 230, 230);
$gray-5: rgb(247, 247, 247);
$blue-2: #d8e6fa;
$blue-3: #B3C9EA;
$blue-4: opacify(#152F5B, 0.16);
$blue-5: #152F5B;
$blue-6: #000d25;
