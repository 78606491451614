@use './styles/colors' as *;

@use './styles/react-photo-view';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: $black-1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited {
  color: unset;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
