@use '../../styles/colors' as *;
@use '../../styles/mixins/device' as *;

.devExperience {
  background: linear-gradient(to bottom, opacify($blue-3, 0.9), opacify($blue-2, 0.5) 80%, $blue-2),
              linear-gradient(-40deg, opacify($blue-3, 0.8), opacify($blue-3, 0.5), opacify($blue-3, 0.7)),
              linear-gradient(125deg, opacify($blue-3, 0.8), opacify($blue-3, 0.6), opacify($blue-3, 0.8));

  @include laptop {
    scroll-margin-top: -175px;
    padding: 125px 175px 100px;
  }

  @include tablet {
    scroll-margin-top: -175px;
    padding: 125px 50px 100px;
  }

  @include mobile {
    scroll-margin-top: -125px;
    padding: 125px 5px 100px;
  }

  .title {
    color: $blue-5;
  }

  .summary {
    margin: 50px 75px;

    .paragraph {
      color: $blue-5;
    }

    .noWrap {
      white-space: nowrap;
    }

    @include mobile {
      margin: 50px 20px;
    }
  }

  .frameworks {
    display: flex;
    flex-wrap: wrap;
    gap: 75px;
    margin: 25px 0;
    padding: 35px 50px 25px;
    border-radius: 25px;
    background: linear-gradient(40deg, opacify($blue-3, 0.9), opacify($blue-3, 0.6), opacify($blue-2, 0.8));
    box-shadow: 0 0 175px opacify($white-1, 0.5);

    @include mobile {
      gap: 40px;
      padding: 35px 20px 25px;
    }
  }

  .angular, .react {
    flex: 1;
    max-width: 100%;
  }
}
