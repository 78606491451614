@use '../../styles/colors' as *;
@use '../../styles/z-index' as *;
@use '../../styles/mixins/device' as *;

.aboutMe {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient($black-1, $blue-6);
  box-shadow: 0 0 45px $blue-6;
  z-index: $about-me-z-index;

  @include laptop {
    padding: 100px 250px 125px;
  }

  @include tablet {
    padding: 100px 125px 125px;
  }

  @include mobile {
    padding: 100px 25px 125px;
  }

  .title {
    margin: 0 0 60px;
    color: $blue-2;

    @include mobile {
      margin: 0 0 30px;
    }
  }

  .paragraph {
    color: $blue-2;
  }

  strong {
    font-weight: 600;
  }
}
