@use './colors' as *;
@import 'react-photo-view/dist/react-photo-view.css';

.PhotoView-Portal {
  .PhotoView-Slider__ArrowLeft svg,
  .PhotoView-Slider__ArrowRight svg,
  .PhotoView-Slider__BannerWrap {
    background: transparent;
  }
}
