.gallery {
  display: flex;
  gap: 8px;
  margin: 8px 0 32px;
  max-width: 600px;

  li {
    flex: 1;
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    border-radius: 4px;
  }
}
