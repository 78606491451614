@use '../../styles/colors' as *;
@use '../../styles/mixins/device' as *;

.constellation, .lonelyStars {
  position: absolute;
  z-index: -1;
}

.constellation {
  @include device(tablet, mobile) {
    display: none;
  }

  &:nth-of-type(1) {
    top: 90px;
    right: 160px;
    height: 175px;
    aspect-ratio: 2;
  }

  &:nth-of-type(2) {
    bottom: 0;
    left: 75px;
    height: 92%;
    aspect-ratio: 0.9;
  }

  &:nth-of-type(3) {
    bottom: 100px;
    right: 150px;
    width: 13%;
    height: 18%;
  }
}

.lonelyStars {
  @include laptop {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
  }

  @include tablet {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
  }

  @include mobile {
    top: 2%;
    left: 5%;
    width: 90%;
    height: 96%;
  }
}
