@use '../../../styles/colors' as *;
@use '../../../styles/mixins/device' as *;

.container {
  display: flex;
  align-items: center;
  gap: 50px;

  @include mobile {
    gap: 20px;
  }

  .photo {
    width: 125px;
    height: 125px;
    border-radius: 20%;

    @include mobile {
      width: 90px;
      height: 90px;
      border-radius: 20%;
    }
  }

  .description {
    display: flex;
    flex-direction: column;

    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 26px;
      font-weight: 600;
      line-height: 34px;
      color: $blue-6;

      .linkedinIcon {
        display: block;
        width: 22px;
        aspect-ratio: 1;

        @include mobile {
          width: 20px;
        }
      }

      @include mobile {
        font-size: 22px;
      }
    }

    .qualification {
      font-size: 22px;
      font-weight: 300;
      line-height: 34px;
      color: $blue-5;

      @include mobile {
        line-height: 25px;
      }
    }

    .location {
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      color: $blue-5;

      @include mobile {
        display: none;
      }
    }
  }
}
