@use '../../styles/colors' as *;
@use '../../styles/mixins/device' as *;

$labyrinth-main-color: #fdc853;
$labyrinth-secondary-color: #553725;
$shogi-score-tracker-main-color: #3cb371;
$shogi-score-tracker-secondary-color: white;

.petProjects {
  display: flex;
  flex-direction: column;
  background: linear-gradient($blue-2, $white-1);
  color: $blue-5;

  @include laptop {
    scroll-margin-top: -75px;
    padding: 100px 250px 125px;
  }

  @include tablet {
    scroll-margin-top: -75px;
    padding: 100px 125px 125px;
  }

  @include mobile {
    scroll-margin-top: -25px;
    padding: 100px 25px 50px;
  }

  .title {
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .firstProjects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a, a:not(:hover) {
      margin: 9px;
      padding: 6px 24px 8px;
      border-radius: 12px;
      background: opacify($blue-3, 0.7);
      font-size: 25px;
      text-decoration: none;
      transition: 0.2s;

      @include mobile {
        font-size: 22px;
      }
    }

    a:hover {
      background: opacify($blue-3, 0.55);
    }

    a:active {
      background: opacify($blue-3, 0.4);
    }
  }

  .labyrinthLink {
    &, &:not(:hover) {
      margin: 0 -4px;
      padding: 4px 8px 4px 10px;
      border-radius: 12px;
      background: opacify($labyrinth-main-color, 0.5);
      color: $labyrinth-secondary-color;
      font-weight: 400;
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        background: opacify($labyrinth-main-color, 0.25);
      }

      &:active {
        background: opacify($labyrinth-main-color, 0.15);
      }
    }
  }

  .shogiScoreTrackerButton {
    display: flex;
    align-self: center;
    align-items: center;
    gap: 12px;
    margin: 20px 9px 9px;
    padding: 9px 32px 9px;
    border-radius: 24px;
    background: $shogi-score-tracker-main-color;
    color: $shogi-score-tracker-secondary-color;
    font-size: 30px;
    text-decoration: none;
    transition: 0.2s;

    img {
      height: 24px;
    }

    @include mobile {
      font-size: 22px;

      img {
        height: 20px;
      }
    }
  }
}
