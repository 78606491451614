@use '../../../styles/mixins/device' as *;

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;

  @include mobile {
    font-size: 28px;
  }
}
