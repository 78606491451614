@use '../../styles/colors' as *;
@use '../../styles/z-index' as *;
@use '../../styles/mixins/device' as *;

.mainScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  height: 100svh;
  max-height: 1000px;
  background: $white-1;
  z-index: $main-screen-z-index;

  @include laptop {
    min-height: 600px;
  }

  @include tablet {
    min-height: 500px;
  }

  @include mobile {
    min-height: 450px;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 100px 0;

    @include mobile {
      padding: 50px 25px 0;
      margin-bottom: auto;
    }
  }

  .navigation {
    align-self: center;
    margin: auto 0;

    @include device(mobile, tablet) {
      align-self: flex-end;
    }
  }
}
