@use 'sass:list';

$laptop-breakpoint: 1280px;
$mobile-breakpoint: 768px;

@mixin laptop {
  @media (min-width: $laptop-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $mobile-breakpoint) and (max-width: $laptop-breakpoint - 0.01px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-breakpoint - 0.01px) {
    @content;
  }
}

@mixin device($device-names...) {
  @each $device-name in $device-names {
    @if $device-name == 'mobile' { @include mobile { @content } }
    @if $device-name == 'tablet' { @include tablet { @content } }
    @if $device-name == 'laptop' { @include laptop { @content } }
  }
}
