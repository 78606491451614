@use '../../../styles/colors' as *;
@use '../../../styles/mixins/device' as *;

.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 500px;

  .anchor {
    display: inline-block;
    margin: 4px 20px;
    padding: 6px 24px 8px;
    border-radius: 12px;
    font-size: 32px;
    color: $blue-5;
    white-space: nowrap;
    transition: 0.2s;

    @include mobile {
      margin: 0 20px;
      font-size: 26px;
    }

    &:hover {
      background: opacify($blue-3, 0.75);
    }

    &:active {
      background: opacify($blue-3, 0.5);
    }
  }

  @include tablet {
    padding: 0 50px;
  }

  @include mobile {
    width: min-content;
    justify-content: flex-end;
    margin-top: auto;
  }
}
