@use '../../styles/colors' as *;
@use '../../styles/z-index' as *;
@use '../../styles/mixins/device' as *;

.background {
  background: $blue-2;
  z-index: $shogi-z-index;
}

.shogi {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: $black-1;
  letter-spacing: 0.5px;
  box-shadow: 0 0 50px -10px $blue-6;

  @include laptop {
    margin: 15px;
    padding: 75px 235px;
  }

  @include tablet {
    margin: 15px;
    padding: 75px 115px;
  }

  @include mobile {
    margin: 5px;
    padding: 25px 20px;
  }

  .title {
    margin-bottom: 40px;
    color: $white-1;
  }

  .paragraph {
    color: $white-1;
  }

  .links {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 10px 0;
    margin: 0 -24px 24px 0;

    @include mobile {
      margin-top: 10px;
    }

    a, a:not(:hover) {
      margin: 4px;
      padding: 6px 24px 8px;
      border-radius: 12px;
      font-size: 26px;
      font-weight: 500;
      text-decoration: none;
      transition: 0.2s;
    }

    a:hover {
      background: opacify($white-1, 0.95);
    }

    a:active {
      background: opacify($white-1, 0.9);
    }
  }

  .gallery {
    margin: 8px 0 32px;
  }
}
